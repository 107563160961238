<template>
  <div class="about">
    <header-m></header-m>
    <div id="website_about_wrap" style="width: 100%;">
      <!-- banner -->
      <div class="banner">
        <div id="about_banner">
          <a>
            <span class="banner_img"></span>
            <div class="banner_main">
              <h3>公司介绍</h3>
            </div>
          </a>
        </div>
      </div>
      <div class="banner">
        <ul class="banner_tab">
          <li class="active">
            <i class="icon_des"></i>
            <div class="info">
              <h4>公司简介</h4>
            </div>
            <span class="arrow"></span>
          </li>
        </ul>
      </div>
      <div class="about_main">
        <div class="about_des tab_content">
          <div class="cell_title">公司简介</div>
          <div class="about_intro">
            <div class="intro">
              <p>
                苏州雄霸网络有限公司，致力于手机游戏开发制作
                苏州雄霸网络有限公司（以下简称“雄霸网络”）成立于2016年8月，是国内优秀的独立游戏开发商。
                成立至今，雄霸网络专注于手机游戏的开发，倾力打造有深度的精品手游。
                目前公司游戏处于开发期间，公司前期主要以独立游戏为主，后续将紧跟移动互联网的时代浪潮，把重心转移至手机网络游戏开发。
                以高端玩家自主研发技术为支撑，以玩家需求为核心且不拘泥于俗套的创新意识为主导，以精致的游戏画面、炫酷的游戏特效。
                充满代入感的背景音乐为保障，力求为玩家呈现完美的饕餮盛宴。
              </p>
            </div>
          </div>
          <div class="img">
            <img src="../assets/img/about_photo01.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="banner">
        <ul class="banner_tab">
          <li class="active">
            <i class="icon_cul"></i>
            <div class="info">
              <h4>企业文化</h4>
            </div>
            <span class="arrow"></span>
          </li>
        </ul>
      </div>
      <div class="about_main">
        <div class="about_cul tab_content">
          <div class="cell_title">企业文化
          </div>
          <div class="cul_box">
            <ul>
              <li class="l">
                <div class="cul_item h">
                  <h1>用心</h1>
                  <h2>HEART</h2>
                  <h3>真诚用心，精益求精</h3>
                  <div class="line"></div>
                  <div class="flag">H</div>
                </div>
              </li>
              <li class="r">
                <div class="cul_item o">
                  <h1>奉献</h1>
                  <h2>OFFER</h2>
                  <h3>竭诚奉献，始终不变</h3>
                  <div class="line"></div>
                  <div class="flag">O</div>
                </div>
              </li>
              <li class="l">
                <div class="cul_item p">
                  <h1>专业</h1>
                  <h2>PROFESSIONAL</h2>
                  <h3>技术专业，产品无界</h3>
                  <div class="line"></div>
                  <div class="flag">P</div>
                </div>
              </li>
              <li class="r">
                <div class="cul_item e">
                  <h1>效率</h1>
                  <h2>EFFICIENT</h2>
                  <h3>保持效率，目标如炬</h3>
                  <div class="line"></div>
                  <div class="flag">E</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
// import '@/assets/js/flexible'   // 头引入过
import HeaderM from "../components/HeaderM.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "AboutM",
  components: {
    HeaderM,
    FooterContent
  },
  data() {
    return {
      active1: "active",
      active2: ""
    };
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      // this.$router.replace('/aboutm');
    } else {
      // alert("pc端");
      this.$router.replace('/about');
    }
  },
  methods: {
    changeTab(tab) {
      if (tab === "active1") {
        this.active1 = "active";
        this.active2 = "";
      } else {
        this.active1 = "";
        this.active2 = "active";
      }
    }
  }
};
</script>

<style scoped>
@import "../assets/css/index.css";
@import "../assets/css/history.css";
.banner {
  min-width: 300px;
}
.banner_img {
  background: url("../assets/img/about_banner.jpg") no-repeat center top;
}
.banner_tab li.active i.icon_des {
  background: url("../assets/img/icon_des_on.png") no-repeat;
}
.banner_tab li i.icon_des {
  background: url("../assets/img/icon_des.png") no-repeat;
}
.banner_tab li.active i.icon_cul {
  background: url("../assets/img/icon_cul_on.png") no-repeat;
}
.banner_tab li i.icon_cul {
  background: url("../assets/img/icon_cul.png") no-repeat;
}
.about_cul {
  width: 100%;
}
.about_cul .cul_box {
  width: 100%;
  min-height: 200px;
  /* background: url("../assets/img/culture.png") center center no-repeat; */
  cursor: pointer;
}
.about_cul .cul_box ul{
  overflow: hidden;
    padding-top: 50px;
}
.about_cul .cul_box ul li .cul_item.h:hover {
  background-image: url("../assets/img/about_h.jpg");
}
.about_cul .cul_box ul li .cul_item.o:hover {
  background-image: url("../assets/img/about_o.jpg");
}
.about_cul .cul_box ul li .cul_item.p:hover {
  background-image: url("../assets/img/about_p.jpg");
}
.about_cul .cul_box ul li .cul_item.e:hover {
  background-image: url("../assets/img/about_e.jpg");
}
.about_main {
  width: 100%;
}
.about_main .about_intro .intro{
  width: 80%;
  padding: 20px 9%;
  margin-right: 530px;
  margin-left: 0;
}
.about_main .tab_content {
  width: 100%;
}
.about_des .img img{
  display: block;
  width: 80%;
  margin: 10px 9%;
}
.about_main .about_des {
  padding-bottom: 100px;
}
</style>
